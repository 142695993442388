<template>
  <div>

    <!-- Header: Personal Info -->
    <div class="d-flex">
      <feather-icon
        icon="UserIcon"
        size="19"
      />
      <h4 class="mb-0 ml-50">
        Personal Information
      </h4>
    </div>

    <!-- Form: Personal Info Form -->

    <!-- BODY -->
    <validation-observer
      #default="{ handleSubmit }"
      ref="refFormObserver"
    >
      <!-- Form -->
      <b-form
        class="p-2"
        @submit.prevent="handleSubmit(onSubmit)"
      >
        <input
          v-model="customerData.ID"
          type="hidden"
        >
        <b-row>

          <!-- Field: MemberID -->
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Membership Number"
              label-for="MemberID"
            >
              <b-form-input
                id="MemberID"
                v-model="customerData.MemberID"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Email -->
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Email"
              label-for="Email"
            >
              <b-form-input
                id="Email"
                v-model="customerData.Email"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Area Code -->
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Area Code"
              label-for="AreaCode"
            >
              <v-select
                v-model="customerData.AreaCode"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="areaCodeOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="AreaCode"
                disabled
              />
            </b-form-group>
          </b-col>

          <!-- Field: Mobile -->
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <b-form-group
              label="Mobile"
              label-for="Mobile"
            >
              <b-form-input
                id="Mobile"
                v-model="customerData.Mobile"
                disabled
              />
            </b-form-group>
          </b-col>
        </b-row>

        <!-- Header: Redemption Info -->
        <div class="d-flex mt-2">
          <feather-icon
            icon="MapPinIcon"
            size="19"
          />
          <h4 class="mb-0 ml-50">
            Campaign data
          </h4>
        </div>

        <!-- Form: Redemption Info -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- Recycle Stamp -->
            <validation-provider
              #default="validationContext"
              name="Recycle Stamp"
              rules="required|integer"
            >
              <b-form-group
                label="Recycle Stamp"
                label-for="RecycleStamp"
              >
                <b-form-spinbutton
                  id="RecycleStamp"
                  v-model="customerData.RecycleStamp"
                  :min="minRecycleStamp"
                  class="w-100"
                  @change="CheckGift"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- Fist login time-->
            <b-form-group
              label="Fist login time"
              label-for="FirstLoginTime"
            >
              {{ customerData.FirstLoginTime }}
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- Sleeves Purchased -->
            <validation-provider
              #default="validationContext"
              name="Sleeves Purchased"
              rules="required|integer"
            >
              <b-form-group
                label="Sleeves Purchased"
                label-for="SleevesPurchased"
              >
                <b-form-spinbutton
                  id="SleevesPurchased"
                  v-model="customerData.SleevesPurchased"
                  :min="minSleevesPurchased"
                  class="w-100"
                  @change="CheckGift"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- Master Origin or Reviving Origins sleeve -->
            <b-form-group
              label="Master Origin or Reviving Origins sleeve"
              label-for="MasterOrigin"
            >
              <b-form-checkbox
                id="MasterOrigin"
                v-model="customerData.PurchasedSpecialOrigin"
                switch
                inline
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- RedeemGift1 -->
            <b-form-group
              label="Redeem Gift 1"
              label-for="RedeemGift1"
            >
              <b-form-checkbox
                id="RedeemGift1"
                v-model="customerData.RedeemGift1"
                switch
                inline
                :disabled="!avaibleRedeemGift1"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- RedeemGift1Time -->
            <b-form-group
              label="Redeem Gift 1 Time"
              label-for="RedeemGift1Time"
            >
              {{ customerData.RedeemGift1Time }}
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- RedeemGift2 -->
            <b-form-group
              label="Redeem Gift 2"
              label-for="RedeemGift2"
            >
              <b-form-checkbox
                id="RedeemGift2"
                v-model="customerData.RedeemGift2"
                switch
                inline
                :disabled="!avaibleRedeemGift2"
              />
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
            md="6"
            lg="6"
          >
            <!-- RedeemGift1Time -->
            <b-form-group
              label="Redeem Gift 2 time"
              label-for="RedeemGift2Time"
            >
              {{ customerData.RedeemGift2Time }}
            </b-form-group>
          </b-col>

          <b-col
            cols="12"
          >
            <!-- Recycle Stamp -->
            <validation-provider
              #default="validationContext"
              name="Comment"
              rules="required"
            >
              <b-form-group
                label="Comment"
                label-for="Comment"
              >
                <b-form-textarea
                  id="Comment"
                  v-model="customerData.Comment"
                  required
                  class="w-100"
                />
                <b-form-invalid-feedback>
                  {{ validationContext.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <b-row class="mt-2">
          <b-col>
            <b-button
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              :to="{ name: 'customer-list' }"
            >
              Back
            </b-button>
            <b-button
              variant="primary"
              class="mb-1 mb-sm-0 mr-0 mr-sm-1"
              :block="$store.getters['app/currentBreakPoint'] === 'xs'"
              type="submit"
            >
              Save Changes
            </b-button>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow, BCol, BForm, BFormGroup, BFormInput, BButton,
  BFormCheckbox,
  BFormTextarea,
  BFormSpinbutton,
  BFormInvalidFeedback,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// import formValidation from '@core/comp-functions/forms/form-validation'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import store from '@/store'
import router from '@/router'

export default {
  components: {
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BFormTextarea,
    vSelect,
    BButton,
    BFormSpinbutton,
    BFormInvalidFeedback,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    customerData: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      required,
      ogCustomerData: null,
      avaibleRedeemGift1: false,
      avaibleRedeemGift2: false,
    }
  },
  setup(props) {
    const toast = useToast()

    const onSubmit = () => {
      store.dispatch('app-customer/updateCustomer', props.customerData)
        .then(() => {
          router.push({ name: 'customer-list' })
        }).catch(error => {
          let ErrorMsg = 'Error to update customer'
          if (error.data.Success === false) {
            ErrorMsg = error.data.Msg
          }

          toast({
            component: ToastificationContent,
            props: {
              title: ErrorMsg,
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    let minRecycleStamp = 0
    let minSleevesPurchased = 0

    const areaCodeOptions = [
      { text: '+852', value: '+852' },
      { text: '+853', value: '+853' },
    ]

    if (props.customerData.RedeemGift1) {
      minRecycleStamp = 5
    }

    if (props.customerData.RedeemGift2) {
      minRecycleStamp = 10
      minSleevesPurchased = 10
    }

    return {
      areaCodeOptions,
      onSubmit,
      minRecycleStamp,
      minSleevesPurchased,
      // resetData,
    }
  },
  methods: {
    CheckGift() {
      // this.avaibleRedeemGift1 = false
      // if (this.customerData.RecycleStamp >= 5) {
      //   this.avaibleRedeemGift1 = true
      // }

      // this.avaibleRedeemGift2 = false
      // if (this.customerData.RecycleStamp >= 10) {
      //   this.avaibleRedeemGift2 = true
      // }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
